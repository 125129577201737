<template>
<div>
    <navBar />
    <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
            <main>
                <Spinner />
                <div class="container-xl px-4">
                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-6 col-md-9 col-sm-11">
                            <div class="card my-5">
                                <div class="card-body p-3 text-center">
                                    <div class="h3 fw-light titulo-login">Registraci&oacute;n de Proveedor/a</div>
                                </div>
                                <hr class="my-0" />
                                <div class="card-body p-5">
                                    <div class="row">
                                        <div class="mb-3 text-left col-md-12">
                                            <label class="text-gray-600 small " for="emailExample">CUIT de la empresa</label>
                                            <input class="form-control form-control-solid send-data-register form-register" @input="validateKeyRegularExpression($event,'[0-9]{11}$')" maxlength="11" @keypress="isNumber($event)" @paste="onPaste" id="CUIT001" v-model="cuit" type="text" placeholder="" aria-label="cuit empresa" />
                                        </div>
                                        <div class="mb-3 text-left col-md-6">
                                            <label class="text-gray-600 small " for="emailExample">Nombre</label>
                                            <input class="form-control form-control-solid send-data-register form-register" id="nombre" v-model="nombre" type="text" placeholder="" aria-label="nombre solicitante" />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="text-gray-600 small text-left" for="passwordExample">Apellido</label>
                                            <input class="form-control form-control-solid send-data-register form-register" id="APE001" v-model="apellido" type="text" placeholder="" aria-label="apellido solicitante" />
                                        </div>
                                        <div class="mb-3 col-md-2">
                                            <label class="text-gray-600 small text-left" for="passwordExample">C. &Aacute;rea</label>
                                            <input class="form-control form-control-solid send-data-register form-register" maxlength="4" @input="validateKeyRegularExpression($event,'[0-9]{2,4}$')" @keypress="isNumber($event)" v-model="codigoArea" id="AREATEL001" type="text" placeholder="" aria-label="código de área" />
                                        </div>
                                        <div class="mb-3 col-md-4">
                                            <label class="text-gray-600 small text-left" for="passwordExample">Tel&eacute;fono</label>
                                            <input class="form-control form-control-solid send-data-register form-register" maxlength="8" @input="validateKeyRegularExpression($event,'[0-9]{6,8}$')" @keypress="isNumber($event)" id="TEL001" v-model="telefono" type="text" placeholder="" aria-label="teléfono" />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="text-gray-600 small text-left" for="passwordExample">Tipo Proveedor/a</label>
                                            <select class="form-control form-control-solid send-data-register form-register" id="tpProveedor" v-model="tipoProveedor" aria-label="tipo proveedor">
                                                <option v-for="(dato) in this.proveedores" :key="dato.id"  :value="dato.codigo" v-if="dato.visible == null || dato.visible == 'S'">{{dato.descripcion}}</option>
                                            </select>
                                        </div>
                                        <div class="mb-3 col-md-12">
                                            <label class="text-gray-600 small text-left" for="passwordExample">Email</label>
                                            <input oncopy="return false" onpaste="return false" @input='validateKeyRegularExpressionEmail($event)' class="form-register form-control form-control-solid send-data-register" v-model="email" type="text" placeholder="" id="email" aria-label="email" />
                                        </div>
                                        <div class="mb-3 col-md-12">
                                            <label class="text-gray-600 small text-left" for="passwordExample">Confirmaci&oacute;n del Email</label>
                                            <input oncopy="return false" onpaste="return false" @input="validateKeyRegularExpressionEmail($event)" class="form-register form-control form-control-solid send-data-register" v-model="emailConfirmacion" type="text" placeholder="" id="confirmEmail" aria-label="confirmación email" />
                                        </div>
                                        <div class="mb-3 col-md-12">
                                            <label class="text-gray-600 small text-left" for="passwordExample">Breve Reseña de los Bienes / Servicios Ofrecidos</label>
                                            <textarea class="form-control form-control-solid send-data-register form-register" type="text" placeholder="" id="resena" v-model="resena" aria-label="reseña" />
                                            </div>
                                        <div class="d-flex align-items-center justify-content-center mb-0 pt-3">
                                            <a class="btn btn-primary color-btn lift lift-sm" @click="getRegistrarse">Solicitar Acceso</a>
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-0" />
                                <div class="card-body px-5 py-4">
                                    <div class="small text-center">
                                        ¿Ya ten&eacute;s usuario?
                                        <a class="btn-link" @click="redirectLogin">¡Ingres&aacute;!</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</div>
</template>

<style>
/*Color del fondo, sobreescribiendo variable de del tema de boostrap SB ADMIN*/
:root {
    --bs-body-bg: #F5F5F5;
}

.form-register {
    padding: 0.875rem 1rem !important;
}

.titulo-login {
    font-size: 30px;
    color: rgb(0, 47, 135);
}

.color-btn {
    background: #002f87 !important;
}

.btn-link {
    color: #0061f2;
    cursor: pointer;
}

.invalid-field {
    border-color: var(--bs-red);
}

.invalid-field:focus {
    border-color: var(--bs-red);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(220 53 70 / 25%);
}
</style>

<script>
import navBar from "../components/landing/navBar";
import router from '@/router/index.js' // Vue router instance
import Spinner from '../components/spinner'
import $ from 'jquery'
import Vue from 'vue'
import config from '../../properties.config'

export default {
    name: "Home",
    components: {
        navBar,
        Spinner,
    },
    mounted: function () {
        this.$store.commit("SET_BLOQUEADO", false);
        this.$store.dispatch("proveedor/getProveedores");
        // this.$store.dispatch("registrarse/clearState");
    },
    methods: {
        validateKeyRegularExpression(event,validacion) {
            if (validacion == null || validacion.length < 1) {
                return true;
            }

            const invalidationClass = 'invalid-field'

            if (event.target.value == '' || !event.target.value) {
                $(event.target).removeClass(invalidationClass)
                return true
            }
            const regex = new RegExp(validacion);

            if (!regex.test(event.target.value)) {
                $(event.target).addClass(invalidationClass)
                return false;
            }
            $(event.target).removeClass(invalidationClass)
            return true;
        },
        validateKeyRegularExpressionEmail(event) {
            const validacion =  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (validacion == null || validacion.length < 1) {
                return true;
            }

            const invalidationClass = 'invalid-field'

            if (event.target.value == '' || !event.target.value) {
                $(event.target).removeClass(invalidationClass)
                return true
            }
            const regex = new RegExp(validacion);

            if (!regex.test(event.target.value)) {
                $(event.target).addClass(invalidationClass)
                return false;
            }
            $(event.target).removeClass(invalidationClass)
            return true;
        },
        redirectLogin() {
            router.push("/login");
        },
        onPaste(evt) {
            this.$store.commit("registrarse/SET_CUIT", this.getDataObj(evt.clipboardData.getData('text').replaceAll('-', ''), config.ATRIBUTTE_CUIT, config.ATRIBUTTE_CUIT_DESC));
            if (!evt.clipboardData.getData('text').includes("-")) {
                return true;
            } else {
                evt.preventDefault();;
                $("#CUIT001").val(evt.clipboardData.getData('text').replaceAll('-', ''));
            }
        },
        getRegistrarse() {
            try {
                this.validateForm();
                this.$store.dispatch("registrarse/createNewUser", [this.getDataForm(), 0, "usuario"]);
            } catch (e) {
                Vue.swal("Registración", e, "info");
            }
        },
        validateForm() {
            $('.send-data-register').each(function (i, obj) {
                //test
                if (!($(this).val() != null && $(this).val().length >= 1)) {
                    throw ("El campo " + this.ariaLabel + " es obligatorio");
                }
            });
            let email = $("#email").val();
            let emailConfirmacion = $("#confirmEmail").val();
            if (this.validateCuit()) {
                throw ("El cuit ingresado no es válido. Debe contener 11 dígitos.");
            }
            if (email != emailConfirmacion) {
                throw ("Los emails no coinciden");
            }
            if (this.validateEmail()) {
                throw ("El email ingresado no es válido");
            }
            if (this.validateCodCelular()) {
                throw ("El código de área no es válido. Debe contener de 2 a 4 dígitos.");
            }
            if (this.validateCelular()) {
                throw ("El número de teléfono ingresado no es válido. Debe tener entre 6 y 8 dígitos.");
            }
        },

        getDataForm() {
            let data = new Object();
            data.titulo = "Registración";
            data.codigoTipoTramite = config.ATRIBUTTE_CODIGO_TIPO_TRAMITE;
            data.deft_tramite_codigo = "WO001";
            data.deft_tramite_tipo_codigo = "TR0006";

            data.atributos = this.getArrayAtributtes();

            return data;
        },
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        getDataObj(value, code, desc) {
            let atributte = new Object();
            atributte.codigo = code;
            atributte.descripcion = desc;
            //se hace esto agregar el codigo de area en el atributo 
            if (code == 'AT004') {
                let codArea = $("#AREATEL001").val();
                atributte.valor = codArea + "-" + value;
            } else {
                atributte.valor = value.trim();
            }
            return atributte;
        },
        getArrayAtributtes() {
            let array = new Array();
            array.push(this.$store.state.registrarse.nombre)
            array.push(this.$store.state.registrarse.apellido)
            array.push(this.$store.state.registrarse.cuit)
            array.push(this.$store.state.registrarse.email)
            array.push(this.$store.state.registrarse.emailConfirmacion)
            array.push(this.$store.state.registrarse.telefono)
            array.push(this.$store.state.registrarse.tipoProveedor)
            array.push(this.$store.state.registrarse.resena)

            return array;
        },
        validateEmail() {
            let data = $("#email").val();
            let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
            if (!emailRegex.test(data)) {
                return true;
            }
            return false;
        },
        validateCuit() {
            let cuit = $("#CUIT001").val();
            if (cuit.length != 11) {
                return true;
            }
            return false;
        },
        validateCodCelular() {
            let data = $("#AREATEL001").val();
            if (data.length < 2 || data.length > 4) {
                return true;
            }
            return false;
        },
        validateCelular() {
            let data = $("#TEL001").val();
            if (!(data.length >= 6 && data.length <= 8)) {
                return true;
            }
            return false;
        }

    },
    computed: {
        nombre: {
            get() {},
            set(value) {
                this.$store.commit("registrarse/SET_NOMBRE", this.getDataObj(value, config.ATRIBUTTE_NOMBRE, config.ATRIBUTTE_NOMBRE_DESC));
            },
        },
        apellido: {
            get() {},
            set(value) {
                this.$store.commit("registrarse/SET_APELLIDO", this.getDataObj(value, config.ATRIBUTTE_APELLIDO, config.ATRIBUTTE_APELLIDO_DESC));
            },
        },
        cuit: {
            get() {},
            set(value) {
                this.$store.commit("registrarse/SET_CUIT", this.getDataObj(value.replaceAll('-', ''), config.ATRIBUTTE_CUIT, config.ATRIBUTTE_CUIT_DESC));
            },
        },
        email: {
            get() {},
            set(value) {
                this.$store.commit("registrarse/SET_EMAIL", this.getDataObj(value, config.ATRIBUTTE_EMAIL, config.ATRIBUTTE_EMAIL_DESC));
            },
        },
        emailConfirmacion: {
            get() {},
            set(value) {
                this.$store.commit("registrarse/SET_EMAILCONFIRMACION", this.getDataObj(value, config.ATRIBUTTE_EMAIL_CONFIRMACION, config.ATRIBUTTE_EMAIL_CONFIRMACION_DESC));
            },
        },
        telefono: {
            get() {},
            set(value) {
                this.$store.commit("registrarse/SET_TELEFONO", this.getDataObj(value, config.ATRIBUTTE_TELEFONO, config.ATRIBUTTE_TELEFONO_DESC));
            },
        },
        tipoProveedor: {
            get() {},
            set(value) {
                this.$store.commit("registrarse/SET_TIPOPROVEEDOR", this.getDataObj(value, config.ATRIBUTTE_TP_PROVEEDOR, config.ATRIBUTTE_TP_PROVEEDOR_DESC));
            },
        },
        codigoArea: {
            get() {},
            set(value) {
                this.$store.commit("registrarse/SET_CODIGO_AREA", value);
                //seteo el celular vacio
                $("#TEL001").val("");
            },
        },
        resena: {
            get() {},
            set(value) {
                this.$store.commit("registrarse/SET_RESENA", this.getDataObj(value, config.ATRIBUTTE_RESENA, config.ATRIBUTTE_RESENA_DESC));
            },
        },
        proveedores: {
            get() {
                return this.$store.state.proveedor.proveedores;
            },
          
        },
    },
};
</script>
